import React, { useEffect, useState } from "react";
import Footer from "../Common/Footer";
import Header from "../Common/Header";
import OfferNotificationBar from "../Common/OfferNotificationBar";
import Translations from "../Common/Translations";
import {
  Typography,
  Container,
  Grid,
  CircularProgress,
  TextField,
  Button,
  Box,
} from "@mui/material";
import { Icon } from "@iconify/react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { contactUsForm } from "../../features/common/commonSlice";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

function ContactUs() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false)
  const { status,message } = useSelector((state) => state.common);
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    setLoading(true)
    try {
      await dispatch(contactUsForm(data)).unwrap();
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(()=>{
    if(status === 'succeeded' && message !== ''){
      toast.success(message)
      reset()
    }
  },[status,message])
  return (
    <>
      <OfferNotificationBar />
      <Header />
      <section className="contact_wrap" style={{ background: "#eef7fe" }}>
        <Container maxWidth={"xl"}>
          <Grid className="contact_inner">
            <Grid className="contact_content">
              <Typography variant="h1">
                <Translations text="Contact Us" />
              </Typography>
              <Typography sx={{ m: "20px 0px" }}>
                <Translations text="We are here to help you. Please contact us via email or live chat." />
              </Typography>
              <Typography>
                <Icon icon="bxs:map" fontSize={18} /> Pharmacy Premium, Pappelallee 78/79,
                Berlin, 10437.
              </Typography>
              <Typography sx={{ m: "10px 0px" }}>
                <Icon icon="tabler:mail-filled" fontSize={18} />{" "}
                <a href="mailto:info@apothekepremium.de">
                  info@apothekepremium.de
                </a>
              </Typography>
            </Grid>
            <Grid>
              <Box className="common_form"
                component="form"
                noValidate
                autoComplete="off"
                sx={{ width: "100%" }}
                onSubmit={handleSubmit(onSubmit)}
              >
                <Controller
                  name="name"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: t("Name is required"),
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label={t("Name")}
                      fullWidth
                      margin="normal"
                      required
                      error={!!errors.name}
                      helperText={errors.name?.message}
                    />
                  )}
                />
                <Controller
                  name="email"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: t("Email is required"),
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label={t("Email")}
                      fullWidth
                      margin="normal"
                      required
                      error={!!errors.email}
                      helperText={errors.email?.message}
                    />
                  )}
                />
                <Controller
                  name="news"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: t("Message is required"),
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label={t("Message")}
                      fullWidth
                      multiline
                      rows={3}
                      margin="normal"
                      required
                      error={!!errors.news}
                      helperText={errors.news?.message}
                    />
                  )}
                />
                <Button className='button1'
                  variant="contained"
                  size='large'
                  fullWidth
                  type="submit"
                  disabled={loading}
                >
                  {loading ? (
                    <CircularProgress size={24} />
                  ) : (
                    t("Sending")
                  )}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </section>
      <Footer />
    </>
  );
}

export default ContactUs;
